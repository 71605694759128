/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import styled, { css } from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  CloudUpload,
  Clear,
  PowerSettingsNew,
  BarChart,
  CardGiftcard,
  Settings,
  HelpOutline,
  Notifications,
  Person,
  ExpandMore,
} from '@material-ui/icons';
import { LinearProgress } from '@material-ui/core';
import CustomMenu from '../menu';
import Timer from '../timer';
import apiConfig from '../../../config';

// Utils
import Mixpanel from '../../../utils/mixpanel';

// Constants
import sidebarTypes from '../Sidebar/constants';

// Styles
import {
  padding,
  colors,
  radius,
  font,
  fs,
  blur,
} from '../../styles/variables';
import { whiteAlpha, blackAlpha } from '../../styles/utils';
import { APP_TYPES } from '../../constants';
import { Flex, Text, CustomAvatarStyles } from '../../styles/common';

// Firebase
import { db, NOTIFICATION_TRACKER } from '../../Firebase';

const LogoWhite = `${apiConfig.assets}/logo-white.svg`;

const useStyles = makeStyles(theme => ({
  teamMenuPopover: {
    margin: '0 !important',
  },
  appbar: {
    boxShadow: 'none',
    backgroundColor: colors.black.main,
  },
  grow: {
    flexGrow: 1,
    maxHeight: '6.5rem',
    '& .MuiToolbar-root': {
      '& a': {
        color: 'transparent',
        outline: 'none',
      },
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  groupAvatar: {
    background: colors.blue.shadeThree,
    color: colors.white,
  },
  groupItemHead: {
    padding: '0',
    fontSize: fs.sm,
    fontWeight: font.semibold,
    color: colors.white,
  },
}));

const CustomToolbar = styled(Toolbar)`
  padding: ${padding.v} ${padding.h};
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(18, 18, 18, 0.8);
`;

const LogoWrapper = styled.div`
  width: 155px;
  img {
    width: 100%;
  }
`;

const ProfileMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
`;

const NotificationButton = styled(IconButton)`
  .MuiSvgIcon-root {
    color: ${colors.blue.shadeTwo};
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.25s ease-in;
    &:hover {
      color: ${colors.blue.primary};
    }
  }
  .MuiBadge-colorSecondary {
    background-color: ${colors.error};
  }
  @keyframes bellMove {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const TeamButtonStyles = styled(IconButton)`
  &.MuiIconButton-root {
    cursor: pointer;
    border: 1px solid ${whiteAlpha(0.2)};
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    padding: 6px;
    &:hover {
      border-color: ${whiteAlpha(0.5)};
    }
    .MuiSvgIcon-root {
      color: ${colors.white};
      font-size: 2rem;
    }
  }
`;

const UpdatedTimerBoxStyles = styled(Flex)`
  border: 1px solid ${whiteAlpha(0.2)};
  border-radius: ${radius.default};
  align-items: center;

  .border-right {
    border-right: 1px solid ${whiteAlpha(0.2)};
  }

  .info {
    width: 150px;
    color: ${whiteAlpha(0.8)};
  }

  button {
    padding: ${padding.container};
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
  }

  .time {
    font-size: 2rem;
  }
`;

const UploadContainerStyles = styled.div`
  width: 30rem;
  .upload-header {
    border-bottom: 0.1rem solid ${whiteAlpha(0.2)};
    padding: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .header-text {
      font-weight: 400;
      padding: 0;
      color: ${whiteAlpha(0.8)};
      font-size: 1.4rem;
    }
    .total-percentage {
      padding: 0;
      font-weight: 400;
      padding: 0;
      color: ${colors.white};
      margin-left: 0.3rem;
      font-size: 1.4rem;
    }
  }
  .upload-list {
    .upload-item {
      padding: 1.5rem 1.5rem 1rem 1.5rem;
      .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        .item-name {
          font-size: 1.2rem;
          font-weight: 400;
          color: #ffffff;
          padding: 0;
          width: 85%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .item-actions {
          button {
            border: 0.1rem solid ${colors.white};
            background: transparent;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.25s ease;
            .MuiSvgIcon-root {
              fill: ${colors.white};
            }
            &:focus {
              outline: none;
            }
            &:hover {
              border: 0.1rem solid ${colors.error};
              background: ${colors.error};
            }
          }
        }
      }
      .item-progress {
        .MuiLinearProgress-root {
          background-color: ${whiteAlpha(0.3)};
          height: 0.2rem;
          .MuiLinearProgress-bar {
            background-color: ${colors.white};
          }
        }
      }
      .item-status {
        padding: 0;
        margin-top: 0.5rem;
        font-size: 1rem;
        color: ${whiteAlpha(0.8)};
      }
    }
  }
`;

const TeamNameStyles = styled.div`
  padding: 0 1.2rem;
  text-align: left;
  p {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16rem;

    &.secondary {
      font-size: 1rem;
    }

    &.separator {
      display: flex;
      align-items: center;

      .dot {
        width: 0.5rem;
        height: 0.5rem;
        margin: 0 0.5rem;
        border-radius: 100%;
        background-color: ${whiteAlpha(0.6)};
      }
    }
  }
`;

const TeamMenuParentStyles = styled(Menu)`
  .MuiPaper-root {
    padding: 0;
    margin-top: 0.75rem;
    background-color: ${blackAlpha(0.5)};
    max-height: 35rem;
    border: 0.15rem solid ${whiteAlpha(0.2)};
    backdrop-filter: blur(${blur});
    border-radius: 0.4rem;
    &::-webkit-scrollbar: {
      width: 0;
    }
    .MuiList-root {
      padding: 0 !important;
    }
  }
`;

const MenuItemSelectedStyles = css`
  background-color: ${({ isSelected }) =>
    isSelected ? colors.blue.secondary : 'transparent'};

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? colors.blue.secondary : whiteAlpha(0.1)};
  }

  .MuiListItem-root {
    pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'auto')};
  }
`;

const TeamMenuItemPersonalStyles = styled.div`
  ${MenuItemSelectedStyles};
  border-top: 1px solid ${whiteAlpha(0.2)};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .MuiListItem-root {
    flex: 1;
    &:hover {
      background-color: transparent;
    }
    .MuiButtonBase-root {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const TeamMenuItemStyles = styled(MenuItem)`
  &.MuiButtonBase-root {
    ${MenuItemSelectedStyles};
    border-top: 1px solid ${whiteAlpha(0.2)};
  }

  &.nested-icon {
    padding: 6px 23px;
    .MuiListItemIcon-root {
      min-width: auto;
    }
    .MuiSvgIcon-root {
      color: ${colors.white};
      width: 2rem;
      height: 2rem;
    }

    &:hover,
    &.active {
      background-color: transparent !important;
      p {
        color: ${colors.blue.primary} !important;
      }
      .MuiSvgIcon-root {
        color: ${colors.blue.primary};
      }
    }
  }
`;

const BaseAccordionStyles = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    width: 25rem !important;
    margin-top: 0 !important;
    border: none !important;
  }
`;

const BaseAccordionSummaryStyles = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: auto;
    &.Mui-expanded {
      min-height: auto;
    }

    .MuiIconButton-root {
      padding: 0;
      margin: 0;
      .MuiSvgIcon-root {
        width: 2rem;
        height: 2rem;
        color: ${colors.white};
      }
    }

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }
  }
`;

const BaseAccordionDetailsStyles = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
    flex-direction: column;
  }
`;

const ADMIN_MENU = [
  {
    icon: <CardGiftcard />,
    name: 'Subscription',
    field: 'subscription',
    path: '/subscription',
  },
  {
    icon: <BarChart />,
    name: 'Statistics',
    field: 'statistics',
    path: '/statistics',
  },
];

export default function Header(props) {
  const teams = useSelector(store => store.common?.teams);
  const cloudSystemDetails = useSelector(
    store => store.common.requestedCloudSystemDetails
  );
  const {
    userDetails,
    profilePhoto,
    history,
    location,
    match: { params },
    uploadFiles,
    toggleSidebar,
    removeUploadData,
    resetHomePrograms,
    selectTeam,
    selectedTeam,
  } = props;

  const classes = useStyles();
  const [totalPercent, setTotalPercent] = useState(0);
  const [notificationDot, setNotificationDot] = useState(false);
  const [expanded, setExpanded] = useState(
    selectedTeam?.team_db_id || localStorage.getItem('team_db_id')
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const UPDATED_TEAMS =
    teams &&
    teams.reduce(
      (acc, curr) => {
        if (curr.is_personal) {
          acc.personal.push(curr);
        } else {
          acc.other.push(curr);
        }
        return acc;
      },
      { personal: [], other: [] }
    );

  const toggleTeamMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const teamMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = item => {
    switch (item.field) {
      case 'settings':
        Mixpanel.track('Settings');
        history.push('/settings');
        break;
      case 'account_settings':
        toggleSidebar({
          type: sidebarTypes.accountSettings,
          show: true,
          title: 'Account Settings',
        });
        break;
      case 'subscription': {
        Mixpanel.track('Subscription');
        history.push('/subscription');
        break;
      }
      case 'statistics': {
        Mixpanel.track('Statistics');
        history.push('/statistics');
        break;
      }
      case 'help_center': {
        Mixpanel.track('HelpCenter');
        window.open('https://postudio.tawk.help/', '_blank');
        break;
      }
      case 'sign_out': {
        Mixpanel.reset();
        Mixpanel.track('Logout');
        history.push('/logout');
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.log(item);
    }
  };

  const baseSelectTeam = team => {
    localStorage.setItem('team_db_id', team?.team_db_id);
    selectTeam({ ...team });
  };

  const moveBell = {
    animation: 'bellMove 0.2s linear 3',
  };

  useEffect(() => {
    if (userDetails) {
      const userId = userDetails.user_id;
      const NOTIFICATION_PING =
        NOTIFICATION_TRACKER + '/' + userId + '/has_new_notification';

      db.ref(NOTIFICATION_PING).on('value', snapshot => {
        const values = snapshot.val();
        setNotificationDot(values);
      });
    }
  }, [userDetails]);

  useEffect(() => {
    const list = uploadFiles.reduce(
      (acc, curr) => {
        acc.total += curr.total;
        acc.completed += curr.completed;
        return acc;
      },
      { total: 0, completed: 0 }
    );
    const percentage = parseInt((list.completed * 100) / list.total, 10);
    if (percentage) {
      // to overcome NaN
      setTotalPercent(percentage);
    }
  }, [uploadFiles]);

  const clearNotificationDot = () => {
    const userId = userDetails.user_id;
    const NOTIFICATION_PING = NOTIFICATION_TRACKER + '/' + userId;
    db.ref(NOTIFICATION_PING).update({ has_new_notification: false });
  };

  const teamMenuSelect = item => {
    resetHomePrograms();
    teamMenuClose();
    baseSelectTeam(item);
    history.push('/');
  };

  const cloudStatusMessage = useCallback(() => {
    if (cloudSystemDetails?.machine_status === 'machine_started') {
      return 'Cloud Session Active';
    }

    if (cloudSystemDetails?.machine_status === 'machine_shutting_down') {
      return 'System shutting down approximately in';
    }

    return 'System getting ready approximately in';
  }, [cloudSystemDetails?.machine_status]);

  const onAccordionMenuToggle = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AdminDropdown = item => {
    return (
      <div aria-label='admin-menu'>
        <BaseAccordionStyles
          expanded={expanded === item?.team_db_id}
          onChange={onAccordionMenuToggle(item?.team_db_id)}>
          <TeamMenuItemPersonalStyles
            isSelected={selectedTeam?.team_db_id === item?.team_db_id}>
            <MenuItem onClick={() => teamMenuSelect(item)}>
              <CustomAvatarStyles
                alt={item?.name}
                className={classes.groupAvatar}>
                {item?.name.charAt(0)}
              </CustomAvatarStyles>
              <TeamNameStyles>
                <Text>{item?.name}</Text>
                {item?.is_personal && (
                  <div className='flex items-center'>
                    <Text color={whiteAlpha(0.6)} marginRight='0.5rem'>
                      {item?.is_personal && 'My Account'}
                    </Text>
                    <Text className='secondary' color={whiteAlpha(0.6)}>
                      {item?.subscription}
                    </Text>
                  </div>
                )}
                {!item?.is_personal && item?.is_admin && (
                  <Text className='secondary separator' color={whiteAlpha(0.6)}>
                    <span>Team Admin</span>
                    <span className='dot' />
                    <span>{item?.aws_config?.region}</span>
                  </Text>
                )}
              </TeamNameStyles>
            </MenuItem>
            <BaseAccordionSummaryStyles expandIcon={<ExpandMore />}>
              {' '}
            </BaseAccordionSummaryStyles>
          </TeamMenuItemPersonalStyles>
          <BaseAccordionDetailsStyles>
            {ADMIN_MENU.map(el => (
              <TeamMenuItemStyles
                className={
                  el.path === location.pathname
                    ? 'nested-icon active'
                    : 'nested-icon'
                }
                onClick={() => {
                  teamMenuClose();
                  onMenuClick(el);
                  baseSelectTeam(item);
                }}>
                <ListItemIcon>{el.icon}</ListItemIcon>
                <Text>{el.name}</Text>
              </TeamMenuItemStyles>
            ))}
          </BaseAccordionDetailsStyles>
        </BaseAccordionStyles>
      </div>
    );
  };

  const TeamMenuItem = data => {
    const item = data?.data;
    return (
      <TeamMenuItemStyles
        style={data?.style}
        onClick={() => teamMenuSelect(item)}
        key={item?.team_db_id}
        isSelected={selectedTeam?.team_db_id === item?.team_db_id}>
        <CustomAvatarStyles alt={item?.name} className={classes.groupAvatar}>
          {item?.name?.charAt(0)}
        </CustomAvatarStyles>
        <TeamNameStyles>
          <Text>{item?.name}</Text>
          <Text className='secondary separator' color={whiteAlpha(0.6)}>
            <span>{data?.subText ?? 'Team Member'}</span>
            <span className='dot' />
            <span>{item?.aws_config.region}</span>
          </Text>
        </TeamNameStyles>
      </TeamMenuItemStyles>
    );
  };

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appbar} position='fixed' color='transparent'>
        <CustomToolbar>
          <Link
            to='/'
            onClick={e =>
              process.env.TYPE === APP_TYPES.app && e.preventDefault()
            }>
            <LogoWrapper>
              <img src={LogoWhite} alt='POSTUDIO' />
            </LogoWrapper>
          </Link>
          <div className={classes.sectionDesktop}>
            {cloudSystemDetails?.project_db_id !== params?.project_db_id &&
              (cloudSystemDetails?.waiting_time ||
                cloudSystemDetails?.session_end_datetime) &&
              (cloudSystemDetails?.machine_status === 'machine_started' ||
                cloudSystemDetails?.machine_status === 'machine_booting' ||
                cloudSystemDetails?.machine_status ===
                  'machine_shutting_down') && (
                <UpdatedTimerBoxStyles>
                  <div className='info'>
                    <Text className='border-right' sm>
                      {cloudStatusMessage()}
                    </Text>
                  </div>
                  <button
                    aria-label='select-team'
                    type='button'
                    onClick={() => {
                      const index = teams.findIndex(
                        item =>
                          item?.team_db_id === cloudSystemDetails?.team_db_id
                      );
                      baseSelectTeam({ ...teams[index] });
                      history.push({
                        pathname: `/project/${cloudSystemDetails?.project_db_id}`,
                        ...(cloudSystemDetails?.task_db_id && {
                          search: `?task=${cloudSystemDetails.task_db_id}`,
                        }),
                      });
                    }}>
                    <Timer
                      endTime={
                        cloudSystemDetails?.waiting_time ||
                        cloudSystemDetails?.session_end_datetime
                      }
                    />
                  </button>
                </UpdatedTimerBoxStyles>
              )}
            {userDetails?.waiting_time &&
              userDetails?.is_subscription_upgrade_in_progress && (
                <UpdatedTimerBoxStyles>
                  <Text className='border-right' sm>
                    Upgrading Subscription.
                  </Text>
                  <Timer endTime={userDetails?.waiting_time} />
                </UpdatedTimerBoxStyles>
              )}

            {uploadFiles && uploadFiles.length ? (
              <CustomMenu
                icon={
                  <CloudUpload
                    style={{
                      fontSize: '2.5rem',
                      margin: '0 1.5rem',
                      fill: '#a5ccff',
                    }}
                  />
                }
                customMenu={
                  <UploadContainerStyles>
                    <div className='upload-header'>
                      <p className='header-text'>Upload Status</p>
                      {totalPercent && (
                        <p className='total-percentage'>{totalPercent}%</p>
                      )}
                    </div>
                    <div className='upload-list'>
                      {uploadFiles.map(item => {
                        return (
                          <div key={item.ingest_db_id} className='upload-item'>
                            <div className='item-header'>
                              <p className='item-name'>
                                {item.asset_info.title}
                              </p>
                              {item.status !== 'finished' && (
                                <div className='item-actions'>
                                  <button
                                    aria-label='abort'
                                    type='button'
                                    className='iconButton'
                                    onClick={() => {
                                      removeUploadData({
                                        id: item.ingest_db_id,
                                        key: 'abort',
                                      });
                                    }}>
                                    <Clear />
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className='item-progress'>
                              <LinearProgress
                                variant='determinate'
                                value={item.percentage}
                              />
                            </div>
                            {item.status === 'pending' ? (
                              <p className='item-status'>In Queue</p>
                            ) : null}
                            {item.status === 'inprogress' ? (
                              <p className='item-status'>{item.percentage}%</p>
                            ) : null}
                            {item.status === 'finished' ? (
                              <p className='item-status'>Finished</p>
                            ) : null}
                            {item.status === 'error' && !item.pause ? (
                              <p className='item-status'>Error</p>
                            ) : null}
                            {item.pause ? (
                              <p className='item-status'>Paused</p>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </UploadContainerStyles>
                }
              />
            ) : null}

            {process.env.TYPE !== APP_TYPES.app && (
              <>
                <NotificationButton color='inherit'>
                  <Badge
                    color='secondary'
                    variant={notificationDot ? 'dot' : ''}
                    style={notificationDot ? moveBell : {}}>
                    <Notifications
                      onClick={() => {
                        toggleSidebar({
                          type: sidebarTypes.notification,
                          show: true,
                          title: 'Notification',
                        });
                        clearNotificationDot();
                      }}
                    />
                  </Badge>
                </NotificationButton>

                {teams && UPDATED_TEAMS && selectedTeam && (
                  <div id='team_switch'>
                    <TeamButtonStyles onClick={toggleTeamMenu}>
                      <div className='flex items-center'>
                        <CustomAvatarStyles
                          alt={selectedTeam?.name}
                          className={classes.groupAvatar}>
                          {selectedTeam?.name?.charAt(0)}
                        </CustomAvatarStyles>
                        <TeamNameStyles>
                          <Text style={{ whiteSpace: 'nowrap' }}>
                            {selectedTeam?.name}
                          </Text>
                          <div className='flex items-center'>
                            {selectedTeam?.is_personal && (
                              <Text
                                color={whiteAlpha(0.6)}
                                className='secondary'
                                marginRight='0.5rem'>
                                {selectedTeam?.is_personal && 'My Account'}
                              </Text>
                            )}
                            <Text color={whiteAlpha(0.6)} className='secondary'>
                              {selectedTeam?.subscription}
                            </Text>
                            {!selectedTeam?.is_personal && (
                              <Text
                                color={whiteAlpha(0.6)}
                                className='secondary'
                                marginLeft='0.5rem'>
                                {selectedTeam?.is_admin ? 'Admin' : 'Member'}
                              </Text>
                            )}
                            <Text
                              className='secondary separator'
                              color={whiteAlpha(0.6)}>
                              <span className='dot' />
                              <span>{selectedTeam?.aws_config?.region}</span>
                            </Text>
                          </div>
                        </TeamNameStyles>
                      </div>
                      <ExpandMore />
                    </TeamButtonStyles>
                    <TeamMenuParentStyles
                      className={classes.teamMenuPopover}
                      id='team-menu'
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={teamMenuClose}
                      elevation={0}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                      {UPDATED_TEAMS.personal.length > 0 && (
                        <div>
                          <Text color={whiteAlpha(0.6)} padding='12px 16px' xs>
                            MY ACCOUNT
                          </Text>
                          {UPDATED_TEAMS.personal.map(item => (
                            <>
                              {process.env.IS_ENTERPRISE_ACCOUNT === 'true' ? (
                                <TeamMenuItem
                                  data={item}
                                  subText={item?.subscription}
                                  style={{
                                    borderBottom: `1px solid ${whiteAlpha(
                                      0.2
                                    )}`,
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    borderBottom: `1px solid ${whiteAlpha(
                                      0.2
                                    )}`,
                                  }}>
                                  <AdminDropdown {...item} key={item?.team} />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}

                      {UPDATED_TEAMS.other.length > 0 && (
                        <div>
                          <Text color={whiteAlpha(0.6)} padding='12px 16px' xs>
                            MY TEAMS
                          </Text>
                          {UPDATED_TEAMS.other.map(item => (
                            <>
                              {item?.is_admin && (
                                <AdminDropdown
                                  {...item}
                                  key={item?.team_db_id}
                                />
                              )}
                              {!item?.is_admin && <TeamMenuItem data={item} />}
                            </>
                          ))}
                        </div>
                      )}
                    </TeamMenuParentStyles>
                  </div>
                )}

                {userDetails && (
                  <CustomMenu
                    onMenuItemClick={item => onMenuClick(item)}
                    menuList={[
                      {
                        icon: <Settings />,
                        name: 'Team Settings',
                        field: 'settings',
                      },
                      {
                        icon: <Person />,
                        name: 'Account Settings',
                        field: 'account_settings',
                      },
                      {
                        icon: <HelpOutline />,
                        name: 'Help Center',
                        field: 'help_center',
                      },
                      {
                        icon: <PowerSettingsNew />,
                        name: 'Sign Out',
                        field: 'sign_out',
                      },
                      {
                        icon: null,
                        name: 'Version 1.16.0',
                        field: 'version',
                        isDisabled: true,
                      },
                    ]}
                    icon={
                      <ProfileMenu>
                        <CustomAvatarStyles
                          alt={userDetails?.first_name}
                          src={profilePhoto}
                          color={userDetails?.user_badge_color}>
                          {userDetails?.first_name.charAt(0)}
                        </CustomAvatarStyles>
                      </ProfileMenu>
                    }
                  />
                )}
              </>
            )}
          </div>
        </CustomToolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  userDetails: PropTypes.instanceOf(Object),
  uploadFiles: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  profilePhoto: PropTypes.string,
  toggleSidebar: PropTypes.bool,
  removeUploadData: PropTypes.func,
  selectTeam: PropTypes.func,
  resetHomePrograms: PropTypes.func,
  selectedTeam: PropTypes.instanceOf(Object),
};
